
/* .countriesContainer div:hover  */

.countriesContainer{
  /* height: calc(100vh - 400px); */
  display: flex;
  flex-direction: column;
  /* margin-bottom: 120px; */
  gap: 16px;
  padding-inline:20px ;

}