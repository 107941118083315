


.country{
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius:12px ;
  border: 1px solid #ACACAC;
  gap:20px;
  transition: .2s ease;
}
.country p{
  margin: 0;
  padding: 0;
}


.country.active{
  border:2px solid var(--common-color);
  color: var(--common-color);
  animation: scaling .5s forwards;
}

.save-button{
    background-color: #f27a2a; /* Orange background color */
  color: white; /* White text */
  border: none; /* Remove default border */
  padding: 10px 40px; /* Padding around the button for height and width */
  font-size: 16px; /* Font size for the text */
  font-weight: bold; /* Bold text */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  display: inline-block;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.save-button:hover {
  background-color: #e66f25; /* Slightly darker shade of orange on hover */
}




