
.searchInput{
  border: 1px solid #D1D1D1; 
  padding: 5px ;
  display: flex;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
} 


.searchInput .searchIcon{
  font-size: 20px;
  color: var(--common-color);
}


.searchInput > input{
  border: none !important;
  outline: none !important;
background-color: transparent;
padding: 3px;
width: 100%;
}

