/* src/BottomDrawer.css */
.bottom-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 500px; /* Adjust as needed */
  overflow: auto;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  z-index: 100000;
  border-radius: 24px 24px 0 0 ;
}


.bottom-drawer.open {
  transform: translateY(0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}


.close-drawer{
  position: absolute;
  top: 27px;
  right: 20px;
}