/* Fullscreen overlay for search */
.search_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Transparent black */
  z-index: 1000; /* Ensure it's above other elements */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 120px; /* Space from the top */
}

/* The search results popup */
.search_popup {
  background: white;
  width: 90%;
  max-width: 380px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-height: 80vh;
  overflow-y: auto;
}

/* No results message styling */
.no-results {
  text-align: center;
  color: gray;
  font-size: 18px;
}

.header_container {
  width: 390px;
  padding: 15px;
  position: sticky;
  top: 0 !important;
  background-color: #fff;
  z-index: 100;
  /* box-shadow: 0 0 18px #d3d3d3; */
  /* border-bottom: 2px solid #d3d3d3; */
}

.header_container .top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_container .header_icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d1d1d1;
  background-color: #f6f6f6;

  transition: 0.3s ease;
}

.header_container .header_icon:active {
  background-color: rgb(193, 193, 193);
}

.header_container .header_icon.english {
  transform: rotate(180deg);
}

.header_container .page_name {
  font-size: 18px;
}

/* search pupup designs */

.searchHeader {
  position: relative;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    /* transform: scale(0.8); */
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    /* transform: scale(1); */
    transform: translateY(0);
  }
}

.fade_out {
  opacity: 1;
  animation: fadeOutScale 0.5s forwards;
}

/* Keyframes for hiding animation */
@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.search_popup .categories_items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 10px;
  width: 100%;
}

.search_category_card {
  width: 100%;
  height: 80px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 5px; */
}

.search_category_card .search_cat_card_image {
  width: 50px;
  height: 50px;
}

.search_category_card .search_cat_card_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  text-align: center;
  font-size: 12px;
}

.search_cat_card_text {
  text-align: center;
  font-size: 12px;
}

.search_products_items {
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px;
  width: 100%;
  margin-bottom: 300px;
}

.searchHeader {
  display: flex;
  gap: 9px;
  align-items: center;
}
