.defaultLayout {
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.start_choose_city {
  height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.bottom_sanve_btn {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: end;
  border-radius: 15px;
}

.bottom_sanve_btn button {
  /* border-radius: 0; */
  width: 100%;
}

/* ------------------------ */
/* ---bottom drower data--- */
/* ------------------------ */

.bottom_drawer_title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 37.48px;
  text-align: center;
}

.servics_container{
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    
}
.servics_container .service {
    padding-inline: 20px;
    
}
.servics_container .service .service_title{
    border-radius: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap:10px;
    padding:11px 16px;
    border: 1px solid var(--common-color);
    font-size:14px
    
}

.servics_container .services_stores{
    display: grid;
    width: 100%;
    /* grid-template-columns:  repeat(5 , auto); */
    grid-template-columns: repeat(auto-fill, minmax(55px , auto)); 
    margin-top: 12px;
    margin-bottom: 24px;
    gap: 19px;
}

.servics_container .services_stores .service_store_image{
    width: 55px;
    cursor: pointer;
    height: 55px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px #0000001A;
    overflow: hidden;
}

.servics_container .services_stores .service_store_image img{
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.service_store_image.active {
  border: 4px solid #79371c;
  scale: 1.09;
}