.prod_search_card {
  box-shadow: 0px 0px 8px 0px #0000001a;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  /* height: 120px; */
  position: relative;
}

.prod_search_card .add_icon {
  position: absolute;
  bottom: 12px;
  left: 13px;
}

.prod_search_card_image {
  width: 166px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain !important;
}

.prod_search_card_image img {
  width: 60%;
  height: 100%;
  margin-block: auto;
  margin-inline: auto;
}

.prod_search_card_content {
  text-align: center;
  padding: 12px;
}

.prod_search_card_content .psc_title {
  font-size: 16px;
  margin: 0;
  margin-bottom: 12px;
}

.prod_search_card_content h6 {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #979797;
  line-height: 17px;
}

.remove_from_cart_button_search {
  position: absolute;
  right: 20px;
  bottom: 15px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  color: var(--common-color);
  font-size: 15px;
  box-shadow: 0px 0px 4px 0px #00000040;
  z-index: 2;
}

.cart_count_padge.right {
  right: 0 !important;
  left: auto !important;
  border-radius: 0;
}
