.footer_container {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  /* padding-right: 20px; */
  gap: 70px;
  position: fixed;
  width: 380px;
  /* min-width: min-content; */
  bottom: 0;
  height: 70px;
  background-color: #fff;
  z-index: 1000;
  border-top: 1px solid #cccccc;
}

.footer_container .footer_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #4b4b4b;
  position: relative;
}

.footer_container .footer_item.active .footer_icon path {
  fill: var(--common-color);
}
.footer_container .footer_item.active .cart_icon path {
  stroke: var(--common-color);
  fill: none;
}

.footer_container .footer_item .footer_icon {
  font-size: 25px;
}
.footer_container .footer_item .footer_icon.cart_icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_container .footer_item .footer_item_text {
  font-size: 14px;
}

.footer_container .footer_item.active .footer_item_text {
  color: var(--common-color);
}

.footer_padge {
  position: absolute;
  top: -10px;
  left: -8px;
  background-color: #29ab7d;
  color: #fff;
  margin: 0;
  min-width: 20px;
  border-radius: 50%;
  height: 20px;
  display: flex;
  font-weight: bold;
  font-size: 10px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
